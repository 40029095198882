.navigation {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  &__btn {
    &:hover {
      text-decoration: none;
    }
  }
  &__btn-prev {
    margin-right: 0.75rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-right: 0;
    }
  }
  &__btn-next {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  &.prev-btn {
    .navigation__btn-next {
      @include media-breakpoint-down(lg) {
        margin-bottom: 0.6rem;
      }
    }
  }
  &__btn-file {
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-bottom: 0.6rem;
    }
  }
  &__desktop-text {
  }
  &__device-text {
  }
  @include media-breakpoint-down(lg) {
    display: block;
  }
}
