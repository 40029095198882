.info-label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  div {
    @include font-family-cera();
  }
  &__count {
    padding: 0 5px;
    border-radius: 50%;
    background-color: #e8e8e8;
    font-size: 0.625rem;
  }
}
