.action-header {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 1.375rem;
  }
  &__circle {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #e8e8e8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__status {
    position: absolute;
    bottom: 0;
    right: 0;
    svg {
      circle {
        fill: var(--theme-primary-color-300);
      }
    }
  }
  &__title {
    font-size: 1rem;
    @include font-family-cera();
  }
  &__subtitle {
    font-size: 0.875rem;
    color: $soft;
  }
}
