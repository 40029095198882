.item-detail {
  &_label {
    color: var(--secondary-color-300);
    font-size: 0.625rem;
    margin-bottom: 0.2rem;
  }
  &_btns {
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    .btn {
      margin-left: 2rem;
      min-width: 9.625rem;
      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
        margin-left: 0;
        margin-right: 1rem;
      }
    }
    button {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    a {
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
}
.date-picker-input {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
