.repair-stores {
  &__list {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  &__item {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  &__action {
    // width: 6.875rem;
    padding: 0 1rem;
  }
}
.repair-store {
}
