.hyperlink {
  border-radius: 0.3125rem;
  background-color: #fafafa;
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  color: #757d8a;
  a {
    color: #060920;
  }
}
