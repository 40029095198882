@font-face {
  font-family: 'comfortaa';
  src: url('../fonts/Comfortaa-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Cera';
  font-weight: 500;
  src: url('../fonts/CeraRoundProMedium.otf');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('../fonts/Inter-Light.woff2?v=3.14') format('woff2'),
    url('../fonts/Inter-Light.woff?v=3.14') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('../fonts/Inter-Regular.woff2?v=3.14') format('woff2'),
    url('../fonts/Inter-Regular.woff?v=3.14') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('../fonts/Inter-Medium.woff2?v=3.14') format('woff2'),
    url('../fonts/Inter-Medium.woff?v=3.14') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('../fonts/Inter-SemiBold.woff2?v=3.14') format('woff2'),
    url('../fonts/Inter-SemiBold.woff?v=3.14') format('woff');
}
* {
  font-family: $font-family-inter, $font-family-cera, serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.heading,
.cera {
  font-family: $font-family-cera;
}
.cera {
  @include font-family-cera();
}
.inter-light {
  @include font-family-inter-light();
}
.inter {
  @include font-family-inter();
}
.inter-medium {
  @include font-family-inter-medium();
}
.inter-semibold {
  @include font-family-inter-semibold();
}
.conforta {
  @include font-family-conforta();
}
.fzxs,
.xsmall {
  font-size: 0.625rem;
}
.fzs,
.small {
  font-size: 0.8125rem;
}
.fzm,
.msmall {
  font-size: 0.875rem;
}
.fzn {
  font-size: 1rem;
}
.soft {
  color: $soft;
}
.xsoft {
  color: $xsoft;
}
.red {
  color: $red;
}
.color-primary {
  color: var(--theme-primary-color-300);
}
.bgsoft {
  background-color: $bgsoft;
}
a {
  color: inherit;
  text-decoration: underline;
  text-decoration-style: dotted;
  &:hover {
    color: var(--theme-primary-color-300);
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}
a.uline-none {
  text-decoration: none;
}
a.tdn {
  text-decoration: none;
}
