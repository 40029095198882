.toolbar {
  padding: 4px;
  background-color: #f7f7f7;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  button {
    border: none;
    background-color: transparent;
    border-radius: 0.375rem;
    margin-right: 2px;
    &:hover {
      background-color: #f0f0f0;
    }
    svg {
      color: #8c8c8c;
    }
  }
}
