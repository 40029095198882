@import '~bootstrap/scss/mixins/banner';
@include bsBanner('');

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
$red: #f3103d;
$primary: $red;
$btn-font-size: 0.9375rem;
// $red: $primary;
$green: #49b263;

$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.75rem;

$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-y-sm: 0.375rem;

$input-font-size: 0.75rem;
$btn-font-size: 0.875rem;

$btn-border-radius: 0.3125rem;
$btn-border-radius-sm: $btn-border-radius;

$container-padding-x: 2.5rem;

// // The components are on top of bootstrap
// @import "~bootstrap/scss/bootstrap";

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 4. Include any optional Bootstrap CSS as needed
// NOTE!!  order is important. Check here node_modules/bootstrap/scss/bootstrap.scss

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

// Any external library
@import 'react-datepicker/dist/react-datepicker.css';

// 6. Add additional custom code here
@import './variables';
@import './themes';
@import './main';
@import './fonts';
@import './texts';
@import './buttons';
@import './forms';
@import './buttons';
@import './colors';
@import './boxs';
@import './icon-container';
@import './logo-akko';
@import './header';
@import './banner';
@import './check-status';
@import './device';
@import './dropdown';
@import './progress-bar';
@import './review-box';
@import './navigation';
@import './navigation-bar';
@import './autosaving';
@import './navigation-menu';
@import './page-header';
@import './describe-incident-form';
@import './type-loss-form';
@import './upload-proof';
@import './image-preview';
@import './proof-loss-list';
@import './review-device';
@import './errors';
@import './breadcrumb';
@import './claim-status';
@import './cancel-claim';
@import './status-label';
@import './action-required';
@import './info-label';
@import './device-item';
@import './label';
@import './affected-items';
@import './device-detail';
@import './collapsable';
@import './action-header';
@import './action-link';
@import './repair-store';
@import './scan-qr';
@import './tab';
@import './message';
@import './close';
@import './login';
@import './claim-preview';
@import './text-editor';
@import './toolbar';
@import './claims-list';
@import './dynamic-component';
@import './repair-cost';
@import './hyperlink';
@import './notification';
@import './evidence';
@import './mailed-device';
@import './finished';
@import './payment';
@import './walkthrough';
@import './no-items';
@import './item-categories';
@import './item-category';
@import './item-detail';
@import './toggle';
@import './loadings';
@import './home';
@import './address';
@import './claims-list-mobile';
@import './claims-list-mobile-device';
@import './navigation-header-mobile';
@import './device-list';
@import './repair-shops';
@import './payment-service-fee';

.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  left: -20px;
}
.btn-save-graph {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.spinner-graph {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.btn-continue {
  display: flex;
  min-height: 2.9rem;
  justify-content: flex-end;
}
.device-icons-storybook {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 10px;
  > div {
    display: block;
    width: 7rem;
    height: 5rem;
  }
}
