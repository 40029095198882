.divider {
  border-top: 1px solid #e8e8e8;
}
.task-item {
  margin-bottom: 1.25rem;
}
.device-detail {
  &__heading {
    margin-bottom: 2.625rem;
    .label {
      font-size: 0.75rem;
    }
  }
  &__box {
    padding: 2.75rem 0 0 0;
    @include media-breakpoint-down(lg) {
      padding: 1rem 0;
    }
    .info-label {
      margin-bottom: 1.875rem;
    }
  }
  &__action {
  }
  &__card {
    border: 1px solid #e8e8e8;
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.3);
      background-color: #fafafa;
    }
    &.open {
      padding: 2.1875rem 2.5rem;
      @include media-breakpoint-down(lg) {
        padding: 1rem;
      }
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}
.device-detail__action {
  .collapsable__body {
    padding-left: 3.6rem;
    @include media-breakpoint-down(lg) {
      padding-left: 0rem;
    }
  }
}
.device-item-header {
  display: flex;

  @include media-breakpoint-down(lg) {
    display: block;
    display: block;
    border: 1px solid #e8e8e8;
    border-radius: 0.375rem;
    margin-bottom: 1.25rem;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    padding: 1.25rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__loss-types,
  &__evidence {
    font-size: 0.875rem;
  }
  &__divider {
    border-left: 1px solid #e8e8e8;
    height: 2.5rem;
    margin-top: 0.5rem;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  .ddcol-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: center;
      height: 2rem;
    }
    .label {
      @include media-breakpoint-down(lg) {
        width: 7rem;
        display: none;
      }
    }
  }
  .ddcol-1 {
    width: 1.8rem;
    padding-top: 0.8rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      display: none;
    }
  }
  .ddcol-2 {
    width: 16%;
    padding-top: 0.3rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #e8e8e8;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .ddcol-3 {
    padding: 0 1rem;
    width: 25%;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      border: none;
      margin-top: 30px;
    }
  }
  .ddcol-4 {
    padding: 0 2.375rem;
    width: 25%;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
      border: none;
    }
  }
  .ddcol-5 {
    padding: 0 0 0 2.375rem;
    width: 25%;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: 0.5rem;
    }
  }
}
.evidence-counter {
  button {
    background-color: transparent;
    border: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
