.home {
  &__heading {
    .btn-file-claim {
      @include media-breakpoint-down(lg) {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        border-radius: 1rem;
        z-index: 10;
      }
    }
  }
}
.claims_not_found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .title {
    color: #060920;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    @include font-family-cera();
  }
  .body-container {
    width: 240px;
  }
  .body {
    color: #757d8a;
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    @include font-family-cera();
  }
  @include media-breakpoint-down(lg) {
    display: block;
  }
  .btn-file-claim {
    border-radius: 1rem;
  }
  .btn-text {
    margin-left: 10px;
    font-size: 13px;
    line-height: 20px;
    @include font-family-inter();
  }
}
