.affected-items {
  .device-item {
    padding-left: 0.75rem;
    @include media-breakpoint-down(lg) {
      padding-left: 0;
    }
  }
  .device-item-action-required {
    margin-right: -30px;
    margin-top: -35px;
    z-index: 9;
    background-color: inherit;
    svg {
      circle {
        fill: var(--theme-primary-color-300);
      }
    }
  }
  &__headers {
    margin-bottom: 1rem;
    display: flex;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    .label {
      font-size: 0.625rem;
    }
  }
  &__items {
    a {
      text-decoration: none;
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    // margin-top: -1px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    @include media-breakpoint-down(lg) {
      border-top: none;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
    &:first-child {
    }
    &:last-child {
      border-bottom: 1px solid #ffffff;
    }
    @include media-breakpoint-down(lg) {
      display: block;
    }
    &:hover {
      background-color: #fbfbfb;
      // border-bottom: 1px solid #E8E8E8;
      @include media-breakpoint-down(lg) {
        background-color: transparent;
        border-top: none;
        border-bottom: none;
      }
    }
  }
  .col-actions {
    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center;
    }
  }
  &__item:last-child {
  }
  &__item-col {
    @include media-breakpoint-down(lg) {
      display: flex;
      margin-bottom: 0.5rem;
      height: 2rem;
      align-items: center;
    }
    .label {
      display: none;
      @include media-breakpoint-down(lg) {
        width: 7rem;
        display: block;
      }
    }
  }
  &__loss-types {
    font-size: 0.875rem;
  }
  &__count {
    font-size: 0.875rem;
  }
  .col-device {
    @include media-breakpoint-down(lg) {
      border-bottom: 1px solid #e8e8e8;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .ai-col1 {
    width: 27%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col2 {
    width: 30%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col3 {
    width: 15%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col4 {
    width: 15%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col5 {
    width: 12%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  &__detail-group {
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    @include font-family-inter-semibold();
  }
  &__detail-value {
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    color: #757d8a;
    @include font-family-inter-semibold();
  }
  &__detail-title {
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    font-size: 0.775rem;
    color: #757d8a;
    @include font-family-inter-semibold();
  }
  &__attention-required {
    width: 135px;
    height: 22px;
    border-radius: 0px 10px 0px 10px;
    background: var(--theme-primary-color-300);
    padding-left: 10px;
    padding-top: 2px;
    font-size: 0.775rem;
    color: #ffffff;
    @include font-family-inter();
  }
}
