.navigation-bar {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    display: block;
  }
  &__autosaving {
    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center;
      margin-bottom: 0.6rem;
    }
  }
  &__btns {
  }
}
