body {
  font-size: 16px;
  color: #060920;
}
a {
  color: #060920;
  text-decoration: none;
  &:hover {
    color: #060920;
    text-decoration: underline;
  }
  &.primary {
    color: var(--link-color);
    &:hover {
      color: #060920;
    }
  }
  &.unone {
    text-decoration: none;
  }
}
.page {
  padding-top: 1px;
}
.titular {
  margin-bottom: 2.125rem;

  &__main {
    font-size: 1.312rem;
    color: $primary-text-color;
    margin-bottom: 0.5rem;
    @include font-family-cera();
  }
  &__secondary {
    font-size: 0.875rem;
    color: $secondary-text-color;
    @include font-family-inter-medium();
  }
}
.line {
  width: 100%;
  border-top: 1px solid #ededed;
}
.loading-page {
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-bg {
  color: var(--preloading-bg-color);
}
.error-box {
  width: 100%;
  max-width: 30rem;
  margin: auto;
  margin-top: 10rem;
  text-align: center;
}

.container-fluid {
  max-width: 80rem;
}
.page {
}
.page.page-internal {
  .header {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }
}
.main {
  max-width: 80rem;
  margin: auto;
  padding: 1.375rem;
  position: relative;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 3px rgba(32, 48, 80, 0.16);
  display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
    padding: 0;
    box-shadow: none;
    margin-bottom: 0.625rem;
  }
  &__navigation-container {
    width: 20.375rem;
  }
  &__navigation {
    width: 20.375rem;
    border-radius: 0.375rem;
    padding: 2.25rem 2.125rem 2.25rem 1.5rem;
    background-color: var(--sidebar-bg-color);
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &__content {
    flex-grow: 1;
    padding: 2.375rem 4.125rem;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
  &--mobile {
    display: block;
  }
  &__content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      min-height: calc(100vh - 11rem);
    }
  }
  &__content-body {
    @include media-breakpoint-down(lg) {
      padding-top: 2.625rem;
    }
  }
  &__cancel {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    padding-top: 0.5rem;
    &:hover {
      opacity: 0.5;
    }
    svg {
      margin: auto;
      display: block;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.main.nopadding {
  padding: 0;
  .main__content {
    padding: 0;
  }
}

.page-claim-detail {
}
.page-claim-device-detail {
  .main {
    padding: 0;
  }
  .main__content {
    padding: 0;
  }
}

.d-none-mobile {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
