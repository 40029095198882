// Colors
.pc300 {
  color: var(--primary-color-300);
}
.pc200 {
  color: var(--primary-color-200);
}
.pc100 {
  color: var(--primary-color-100);
}
.cdark {
  color: var(--dark-color);
}
.sc400 {
  color: var(--secondary-color-400);
}
.sc300 {
  color: var(--secondary-color-300);
}
.sc200 {
  color: var(--secondary-color-200);
}
.sc100 {
  color: var(--secondary-color-100);
}
.sc50 {
  color: var(--secondary-color-50);
}

// BG colors
.pbg300 {
  background-color: var(--primary-color-300);
}
.pbg200 {
  background-color: var(--primary-color-200);
}
.pbg100 {
  background-color: var(--primary-color-100);
}
.bgdark {
  background-color: var(--dark-color);
}
.sbg400 {
  background-color: var(--secondary-color-400);
}
.sbg300 {
  background-color: var(--secondary-color-300);
}
.sbg200 {
  background-color: var(--secondary-color-200);
}
.sbg100 {
  background-color: var(--secondary-color-100);
}
.sbg50 {
  background-color: var(--secondary-color-50);
}
