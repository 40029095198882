.tab {
  &__header {
    margin-bottom: 1rem;
  }
  &__body {
  }
  &__btns {
  }
  &__btn {
    border: 1px solid $bordersoft;
    background-color: #fff;
    border-radius: 3px;
    height: 1.875rem;
    padding: 0 2.75rem;
    font-size: 0.75rem;
    margin-left: -1px;
    &:first-child {
      margin-left: 0px;
    }
  }
  &__btn.active {
    color: var(--tab-button-color);
    border: 1px solid var(--tab-button-border-color);
    background-color: var(--tab-button-bg-color);
    z-index: 1;
    position: relative;
  }
  &__btn.active ~ &__btn {
    // border-left: none;
  }
}
