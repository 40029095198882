.login {
  width: 100%;
  max-width: 25rem;
  display: block;
  margin: auto;
  .title {
    @include font-family-cera();
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
