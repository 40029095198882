.process-finished {
  display: block;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../public/images/bg-process-finished.png');
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 5%;
  background-color: #fdfdfd;
  background-size: auto 100%;
  &.branded {
    background-image: url('../../public/images/bg-process-finished-branded.png');
  }
  img {
    width: 8.875rem;
    margin-bottom: 1.875rem;
  }
  h5 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 0;
    color: #757d8a;
    a {
      color: #060920;
    }
  }
}
