$black: #060920;
$soft: #9ba1ab;
$xsoft: #f3f3f3;
$bgsoft: #e8e8e8;
$bordersoft: #eaeaea;
$bgsoftred: #fef9fa;
$_1px: 1/16rem;
$_2px: 2/16rem;
$_3px: 3/16rem;
$_4px: 4/16rem;
$_5px: 5/16rem;
$_6px: 6/16rem;
$_7px: 7/16rem;
$_8px: 8/16rem;
$_9px: 9/16rem;
$_10px: 10/16rem;
$_11px: 11/16rem;
$_12px: 12/16rem;
$_13px: 13/16rem;
$_14px: 14/16rem;
$_15px: 15/16rem;
$_16px: 1rem;
$_17px: 17/16rem;
$_18px: 18/16rem;
$_19px: 19/16rem;
$_20px: 20/16rem;
$_21px: 21/16rem;
$_22px: 22/16rem;
$_23px: 23/16rem;
$_24px: 24/16rem;

$font-family-cera: 'Cera';
$font-family-inter: 'Inter';
$font-family-conforta: 'comfortaa';

@mixin font-family-cera {
  font-family: $font-family-cera;
}
@mixin font-family-inter-light {
  font-family: $font-family-inter;
  font-weight: 300;
}
@mixin font-family-inter {
  font-family: $font-family-inter;
  font-weight: 400;
}
@mixin font-family-inter-medium {
  font-family: $font-family-inter;
  font-weight: 500;
}
@mixin font-family-inter-semibold {
  font-family: $font-family-inter;
  font-weight: 600;
}
@mixin font-family-conforta {
  font-family: $font-family-conforta;
}

$primary-text-color: #060920;
$secondary-text-color: #9ba1ab;
