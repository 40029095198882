.claims-list {
  &__headers {
    margin-bottom: 1rem;
    display: flex;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &__items {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    padding: 1rem 0.3rem;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    // margin-top: -1px;
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      display: block;
      border: 1px solid #e8e8e8;
      border-radius: 0.375rem;
      margin-bottom: 1.25rem;
      box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
      padding: 1.25rem;
      max-width: 37rem;
      margin-left: auto;
      margin-right: auto;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #fbfbfb;
    }
  }
  &__item:last-child {
  }
  &__item-col {
    font-size: 0.875rem;
  }
  &__item-col-info {
  }
  .ai-col1 {
    width: 17%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col2 {
    width: 30%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col3 {
    width: 12%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col4 {
    width: 22%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .ai-col5 {
    width: 18%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  // &__items-card {
  //     display: none;
  //     a {
  //         text-decoration: none;
  //     }
  //     @include media-breakpoint-down(lg) {
  //         display: block;
  //     }
  // }
}
.claims-list {
  &__claim {
    display: flex;
    align-items: center;
    min-height: 2.375rem;
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      padding-bottom: 0.625rem;
      margin-bottom: 0.625rem;
      border-bottom: 1px solid #e8e8e8;
    }
    .status-label {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    .claim_id {
      display: flex;
      align-items: center;
    }
    .claim_id_model {
      a {
        text-decoration: none;
        @include media-breakpoint-down(lg) {
          font-weight: bold;
        }
      }
    }
    .claim_id_model_id {
      font-weight: 600;
    }
    .claim_model {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
  }
  &__model {
    display: flex;
    align-items: center;
    min-height: 2.375rem;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    min-height: 2.375rem;
    .label {
      font-size: 0.625rem;
      color: #9ba1ab;
      width: 5.5rem;
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    @include media-breakpoint-down(lg) {
      min-height: 2rem;
    }
  }
  &__loss-type {
    display: flex;
    align-items: center;
    min-height: 2.375rem;
    .label {
      font-size: 0.625rem;
      color: #9ba1ab;
      width: 5.5rem;
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
      }
    }
    @include media-breakpoint-down(lg) {
      min-height: 2rem;
    }
  }
  &__status {
    display: flex;
    align-items: center;
    min-height: 2.375rem;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      margin-top: 1rem;
      justify-content: center;
    }
    .status-label {
      margin-right: 1rem;
      margin-left: 0.2rem;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    .icon {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
// .claim-list-item {
//     &__main {
//         @include font-family-cera();
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 16px;
//     }
//     &__main-subtitle {
//         @include font-family-inter();
//         font-weight: 500;
//         font-size: 13px;
//         line-height: 15.73px;
//         color: #F3103D;
//     }
// }
