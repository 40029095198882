.no-items {
  text-align: center;
  padding: 5rem 1rem;
  &_icon {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 50%;
    background-color: #f6f5f5;
    display: flex;
    margin: 0 auto 1rem auto;
    align-items: center;
    justify-content: center;
  }
  &_title {
    @include font-family-cera();
    font-size: 1.125rem;
  }
  &_desc {
    color: var(--secondary-color-300);
    margin-bottom: 1rem;
  }
  &_btn {
    width: 10.625rem;
    border-radius: 20px;
    .btn-text {
      margin-left: 10px;
    }
  }
}
