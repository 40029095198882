.type-loss-form {
  &__type-loss {
  }
  &__description {
    @include media-breakpoint-down(lg) {
      min-height: 10rem;
    }
  }
}
textarea.form-control.type-loss-form__description {
  @include media-breakpoint-down(lg) {
    min-height: 10rem;
  }
}
.multiselect {
  font-size: 0.75rem;
}
.multiselect {
  .multiselect {
    &__control {
      border-color: #ced4da;
      &:hover {
        color: $input-focus-color;
        border-color: var(--input-border-color);
        background-color: $input-focus-bg;
        box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
        outline: 0;
        @if $enable-shadows {
          @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
        }
      }
      &--is-focused {
        border-color: #ced4da;
        box-shadow: none;
      }
    }
    &__indicators {
      padding: 3px 8px;
    }
    &__multi-value__remove {
      &:hover {
        background-color: transparent;
      }
    }
  }
}
