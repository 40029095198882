.claims-list-mobile-device {
  &__main {
    @include font-family-cera();
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
  }
  &__secondary {
    @include font-family-inter();
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    color: #575757;
  }
}
