.scan-qr {
  text-align: center;
  &__box {
    background-color: #fafafa;
    padding: 2rem 4rem;
    border-radius: 3px;
    display: block;
    margin: auto;
    width: 100%;
    max-width: 40rem;
  }
  &__qr {
    width: 5rem;
    height: 5rem;
    display: block;
    margin: auto;
    margin-bottom: 2rem;
    img {
      width: 100%;
    }
  }
}
