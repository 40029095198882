.confirm-address {
  &_input {
  }
  &_opt-container {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
  }
  &_options {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    padding: 0.7rem;
  }
  &_option {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    width: 100%;
    text-align: left;
    padding: 1rem;
    display: block;
    padding: 0.7rem 1rem;
    &:hover {
      background-color: var(--secondary-color-50);
    }
  }
}

.store-address {
  &_input {
  }
  &_opt-container {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
  }
  &_options {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    padding: 0.7rem;
    z-index: 10;
  }
  &_option {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    width: 100%;
    text-align: left;
    padding: 1rem;
    display: block;
    padding: 0.7rem 1rem;
    &:hover {
      background-color: var(--secondary-color-50);
    }
  }
}

.google-places {
  &_input {
  }
  &_opt-container {
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
  }
  &_options {
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    padding: 0.7rem;
    z-index: 10;
  }
  &_option {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    width: 100%;
    text-align: left;
    padding: 1rem;
    display: block;
    padding: 0.7rem 1rem;
    &:hover {
      background-color: var(--secondary-color-50);
    }
  }
}
