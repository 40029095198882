.check-status {
  border-radius: 50%;
  // background-color: $primary;
  border: 1px solid var(--secondary-color-200);
  position: relative;
  width: 1rem;
  height: 1rem;
  &.checked {
    background-color: var(--check-status-color);
    border: none;
  }
  svg {
    position: absolute;
    top: 5px;
    left: 3px;
  }
  &--sm {
    width: 0.8125rem;
    height: 0.8125rem;
    svg {
      position: absolute;
      top: 4px;
      left: 2px;
    }
  }
  &--done {
    background-color: $green;
  }
}
