.image-preview {
  width: 10.625rem;
  height: 7.625rem;
  position: relative;
  border: 1px dashed var(--upload-proof-btn-border);
  border-radius: 3px;
  // overflow: hidden;
  &.loading {
    .image-preview__img {
      opacity: 0.2;
    }
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 0;
  }
  &__data {
    align-items: center;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(lg) {
      display: flex;
    }
    @include media-breakpoint-up(lg) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__spinner {
    display: block;
  }
  &__img {
    width: 100%;
    @include media-breakpoint-down(lg) {
      width: 2rem;
      height: 2rem;
    }
  }
  &__name {
    display: block;
    flex-grow: 1;
    padding: 0 0.5rem;
    font-size: 0.8125rem;
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.2rem;
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      text-align: center;
      flex-grow: 0;
      position: static;
    }
  }
  &__file-name {
    display: block;
    flex-grow: 1;
    padding: 0 0.5rem;
    font-size: 0.8125rem;
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.2rem;
      text-align: center;
      flex-grow: 0;
    }
  }
  &__delete {
    cursor: pointer;
    width: 2rem;
    padding: 0 0.5rem;
    background-color: transparent;
    border: none;
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 0;
      width: 1rem;
    }
    &:hover {
      opacity: 0.4;
    }
    svg {
      path {
        fill: var(--theme-primary-color-300);
      }
    }
  }
  .spinner-loading {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  &_status {
    display: block;
    position: absolute;
    top: -14px;
    right: -3px;
    width: 10px;
    height: 10px;
    z-index: 1;
  }
}
.spinner-loading {
  color: var(--spinner-color);
}
