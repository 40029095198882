.banner {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fefefe;
  height: 10.125rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
  }
  // @include media-breakpoint-up(lg) {
  //     display: none;
  // }
}
