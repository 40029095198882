.repair-shops {
  &__property-name {
    @include font-family-cera();
    margin-bottom: 0.3rem;
  }
  &__property-address {
    @include font-family-inter();
    color: #757d8a;
    margin-bottom: 0.3rem;
  }
}
