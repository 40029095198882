.device-item {
  position: relative;
  @include font-family-cera();
  &__main {
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    font-size: 0.875rem;
    @include font-family-inter-semibold();
  }
  &__second {
    color: $secondary-text-color;
    font-size: 0.75rem;
    margin-right: 1rem;
    text-overflow: ellipsis;
    font-size: 0.875rem;
  }
}
