.page-header {
  margin-top: 0;
  margin-bottom: 1.875rem;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &__arrow_back {
    padding-top: 3px;
    transform: rotate(270deg);
    margin-left: 10px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__title {
    font-size: 1.25rem;
    @include font-family-cera();
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
      font-size: 1.375rem;
      margin-left: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
    }
  }
  &__subtitle {
    font-size: 0.875rem;
    color: #9ba1ab;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
