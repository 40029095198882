.claims-list {
  &__items-card {
    display: none;
    a {
      text-decoration: none;
    }
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}
.claim-list-item {
  &__main {
    @include font-family-cera();
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
  &__main-subtitle {
    @include font-family-inter();
    font-weight: 500;
    font-size: 13px;
    line-height: 15.73px;
    color: #f3103d;
  }
}
