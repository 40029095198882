.navigation-header-mobile {
  display: none;
  margin-top: 0;
  @include media-breakpoint-down(lg) {
    display: block;
  }
  &__arrow_back {
    padding-top: 3px;
    transform: rotate(270deg);
    margin-left: 10px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__title {
    font-size: 1.25rem;
    @include font-family-cera();
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
      font-size: 1.375rem;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
    }
  }
  &__step_number {
    @include font-family-cera();
    padding-top: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  hr {
    height: 2px;
    //   background-image: linear-gradient(90deg, rgba(243,16,61,1) 0%, rgba(243,16,61,1) 20%, rgba(232,232,232,1) 20%, rgba(232,232,232,1) 100%);
    border: 0;
    opacity: 1;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .divider-step-1 {
    background-image: linear-gradient(
      90deg,
      rgba(243, 16, 61, 1) 0%,
      rgba(243, 16, 61, 1) 20%,
      rgba(232, 232, 232, 1) 20%,
      rgba(232, 232, 232, 1) 100%
    );
  }
  .divider-step-2 {
    background-image: linear-gradient(
      90deg,
      rgba(243, 16, 61, 1) 0%,
      rgba(243, 16, 61, 1) 40%,
      rgba(232, 232, 232, 1) 40%,
      rgba(232, 232, 232, 1) 100%
    );
  }
  .divider-step-3 {
    background-image: linear-gradient(
      90deg,
      rgba(243, 16, 61, 1) 0%,
      rgba(243, 16, 61, 1) 60%,
      rgba(232, 232, 232, 1) 60%,
      rgba(232, 232, 232, 1) 100%
    );
  }
  .divider-step-4 {
    background-image: linear-gradient(
      90deg,
      rgba(243, 16, 61, 1) 0%,
      rgba(243, 16, 61, 1) 80%,
      rgba(232, 232, 232, 1) 80%,
      rgba(232, 232, 232, 1) 100%
    );
  }
  .divider-step-5 {
    background-image: linear-gradient(
      90deg,
      rgba(243, 16, 61, 1) 0%,
      rgba(243, 16, 61, 1) 100%
    );
  }
}
