.action-required {
  color: var(--theme-primary-color-300);
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  svg {
    circle {
      fill: var(--theme-primary-color-300);
    }
  }
}
