.item-category {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  background-color: #fff;
  border: none;
  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 1.125rem;
    border-radius: 0.625rem;
    border: 1px solid #e8e7e5;
  }
  &_icon {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #e8e7e5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_detail {
    flex-grow: 1;
    padding: 0 1rem;
  }
  &_arrow {
    width: 1rem;
  }
  &_name {
    @include font-family-cera();
    display: block;
  }
  &_examples {
    font-size: 0.875rem;
    display: block;
    color: var(--secondary-color-400);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 13.875rem;
    @include media-breakpoint-between(lg, xl) {
      text-overflow: initial;
      white-space: normal;
      overflow: visible;
    }
    @media (min-width: 768px) and (max-width: 1199.98px) {
    }
  }
}
