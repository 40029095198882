.text-editor {
  border-radius: 0.375rem;
  .form-control {
    min-height: 12rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
}
.hovering-toolbar {
  padding: 6px;
  position: absolute;
  z-index: 10;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: rgb(34, 34, 34);
  border-radius: 4px;
  transition: opacity 0.75s ease 0s;
  button {
    background-color: transparent;
    border: none;
    &:hover {
      svg {
        color: #fff;
      }
    }
  }
  svg {
    color: #aaaaaa;
  }
  &.demo {
    top: 0;
    left: 0;
    margin-top: 0;
    opacity: 1;
  }
}

.text-editor {
  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    outline: 0;
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      //   box-shadow: $input-focus-box-shadow;
      box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
    }
  }
}

.text-editor.focus {
  .form-control {
    border-color: var(--input-border-color);
  }
  .toolbar {
    border-top: 1px solid var(--input-border-color);
    border-left: 1px solid var(--input-border-color);
    border-right: 1px solid var(--input-border-color);
  }
}
