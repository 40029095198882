.device {
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  border-color: $border-color;
  padding: 0.875rem 1.125rem;
  position: relative;
  &.checked {
    border-color: var(--device-picker-border-color);
  }

  &.disabled {
    opacity: 0.5;
  }

  &__icon-status {
    @include media-breakpoint-down(lg) {
      position: absolute;
      top: -0.3125rem;
      right: -0.3125rem;
    }
  }
  &__model {
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: break-word;
    @include font-family-cera();
    @include media-breakpoint-down(lg) {
      margin-bottom: 0.3125rem;
    }
  }
  &__code {
    color: $secondary-text-color;
    font-size: 0.75rem;
    margin-right: 1rem;
    text-overflow: ellipsis;
    @include font-family-cera();
  }
  &--open {
    .device__head {
      margin-bottom: 1rem;
    }
  }
  &__head {
    cursor: pointer;
  }
  &__body {
  }
  &__actions {
    display: flex;
  }
  &__actions-btn {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    &:hover {
      background-color: var(--secondary-color-50);
    }
  }
  &__check-status {
    margin-right: 0.5rem;
  }
  &__new-item-mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    .affected-device {
      border-style: dashed;
      .link-text {
        @include font-family-inter();
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: 060920;
      }
    }
  }
}
.affected-device {
  cursor: pointer;
}
.affected-device {
  height: 100%;
  display: flex;
  align-items: center;
  .device__head {
    width: 100%;
  }
}
