.status-label {
  font-size: 0.75rem;
  border-radius: 3px;
  padding: 0.125rem 0.375rem;
  text-transform: capitalize;
  &.open,
  &.new,
  &.pending {
    background-color: #dcefff;
    color: #2c90e7;
  }
  &.closed {
    background-color: #e8e8e8;
    color: #757d8a;
  }
  &.draft {
    background-color: #e8e8e8;
    color: #757d8a;
  }
  &.success {
    background-color: #d5e5d3;
    color: #2f7d22;
  }
  &.finished {
    background-color: #d5e5d3;
    color: #2f7d22;
  }
  &.inprogress {
    background-color: #dcefff;
    color: #2c90e7;
  }
  &.rejected {
    background-color: #ffdddd;
    color: #ff7474;
  }
}
