.header {
  margin-top: 1.625rem;
  margin-bottom: 2.5rem;

  &__logo {
    display: flex;
    align-items: center;
    img {
      width: 6.75rem;
    }
  }

  &__icon-back {
    display: flex;
    align-items: center;
  }
}
@include media-breakpoint-up(lg) {
  .header__logo {
    img {
      width: 9.375rem;
    }
  }
}
