.hyperlink {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.dc-label {
  font-size: 0.75rem;
  margin-bottom: 2px;
  text-transform: uppercase;
}
.dc-instruction {
  font-size: 0.8125rem;
  color: $soft;
  margin-bottom: 2px;
}
