.icon-container {
  border-radius: 50%;
  background-color: #e8e7e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;

  &--md {
    width: 2.5rem;
    height: 2.5rem;
  }
}
