.review-claim-page {
  .section-title {
    font-size: 1rem;
    margin-bottom: 0.875rem;
    @include font-family-cera();
  }
}
.review-box {
  padding: 1.25rem;
  background-color: #fef9fa;
  background-color: var(--review-box-color);
  @include media-breakpoint-down(lg) {
    border-radius: 0.625rem;
  }
  @include font-family-inter();
  .title {
    font-size: 0.75rem;
    color: var(--secondary-color-400);
    margin-bottom: 0.2rem;
    svg {
      margin-right: 0.5rem;
      margin-top: -2px;
    }
    &_icon {
    }
    &_txt {
    }
    @include media-breakpoint-down(lg) {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }
  .description {
    font-size: 0.875rem;
    margin-bottom: 4px;
    @include media-breakpoint-down(lg) {
      margin-left: 1.4rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  &__date {
  }
  &__incident {
  }
}
.review-email {
  label {
    font-size: 0.8125rem;
  }
}
.disclaimer {
  margin-top: 3rem;
  margin-bottom: 3rem;
  h5 {
    font-size: 1.125rem;
    margin-bottom: 0.875rem;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 1.75rem;
  }
}
