.btn {
  $shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @include font-family-inter-semibold();
  &--fw {
    width: 100%;
  }
  &--dotted {
    background-color: white;
    color: $primary;
    border-style: dashed;
    box-shadow: $shadow;
  }
  &:hover {
    text-decoration: none;
  }
  &:not(.btn-link) {
    box-shadow: $shadow;
  }
}

// DEPRECATED
.btn2 {
  font-size: $btn-font-size;
  color: $white;
  background-color: $primary;
  padding: 0.641rem 0.75rem;
  display: inline-block;
  line-height: $btn-line-height;
  text-align: center;
  text-decoration: none;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
  border-color: $primary;
  border-style: solid;
  border-color: $primary;
  @include font-family-inter-semibold();
  &:hover {
    box-shadow: 0px 1px 2px 1px rgba($color: #000000, $alpha: 0.1);
  }
  &--fw {
    width: 100%;
  }
  &--secondary {
    background-color: $white;
    border-color: $primary;
    color: $primary;
  }
  &--dotted {
    background-color: $white;
    color: $primary;
    border-style: dashed;
    border-color: #dcdcdc;
  }
}
