@keyframes notification_in {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 2rem;
    opacity: 1;
  }
}
.toast-container {
  position: fixed;
  opacity: 1;
  top: 2rem;
  left: 50%;
  margin-left: -172px;
  animation-name: notification_in;
  animation-duration: 1s;
  &.success {
    .toast {
      border: 1px solid $green-200;
    }
    .toast,
    .toast-header {
      background-color: $green-100;
      color: $green;
    }
  }
  &.warning {
    .toast {
      border: 1px solid $yellow-200;
    }
    .toast,
    .toast-header {
      background-color: $yellow-100;
      color: $yellow;
    }
  }
  &.error {
    .toast {
      border: 1px solid $red-200;
    }
    .toast,
    .toast-header {
      background-color: $red-100;
      color: $red;
    }
  }
}
