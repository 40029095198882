.payment {
  &-zero {
    padding: 0.5rem;
    background-color: #fff0c3;
    border-radius: 5px;
    margin: 0.5rem 0;
  }
}
.payment-label {
  font-size: 0.875rem;
  color: var(--secondary-color-300);
}
.payment-quantity {
  display: flex;
  .payment-label {
  }
  .amount {
    font-size: 2rem;
    font-weight: bold;
    @include font-family-cera();
  }
  .breakdown {
    color: var(--theme-primary-color-300);
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    background-color: transparent;
    border: none;
    padding: 0;
    &.open {
      svg {
        transform: rotate(-90deg);
      }
    }
    svg {
      path {
        stroke: var(--theme-primary-color-300);
      }
    }
  }
}
.payment-breakdown {
  display: block;
  font-size: 0.875rem;
  &__item {
    display: flex;
    margin-bottom: 0.25rem;
    .payment-breakdown-label {
      min-width: 11rem;
    }
  }
  &-label {
    color: var(--secondary-color-300);
  }
  &-amount {
    display: flex;
    justify-content: flex-start;
  }
  .affects-amount {
    margin-left: -0.6875rem;
    width: 0.6875rem;
  }
  .payment-symbol {
  }
}
.payment-agreement {
  margin-bottom: 1.5rem;
}
.payment-confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  background-color: #e9f4e7;
  .message {
    display: flex;
  }
  .check {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #2f7d22;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail {
    margin-left: 0.75rem;
    font-size: 0.875rem;
    &_txt {
    }
    &_date {
      color: var(--secondary-color-300);
    }
  }
  .payment-logo {
    width: 5.375rem;
  }
}
.payment-divider {
  border-bottom: 1px solid var(--secondary-color-100);
  margin: 1rem 0;
}
.payment-payme {
  .title {
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
  .options {
    display: flex;
    margin-bottom: 1.25rem;
  }
  .option-btn {
    width: 10rem;
    height: 2.625rem;
    border-radius: 0.3125rem;
    padding: 0 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--secondary-color-100);
    background-color: #ffffff;
    margin-right: 0.875rem;
    &:last-child {
      margin-right: 0;
    }
    &.checked {
      border: 1px solid var(--secondary-color-200);
      background-color: var(--secondary-color-50);
      .check {
        background-color: var(--theme-primary-color-300);
      }
    }
  }
  .check {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color-100);
  }
  .option-logo.paypal {
    width: 4.875rem;
    height: 1.25rem;
  }
  .option-logo.venmo {
    width: 4.25rem;
    height: 0.875rem;
  }
}
.payment-info {
  .form-label {
    font-size: 0.75rem;
    color: var(--secondary-color-300);
    margin-bottom: 0.375rem;
  }
}
.payment-btn {
  width: 10.25rem;
}
.wire {
  &_title {
    font-size: 0.875rem;
  }
  &_desc {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: var(--secondary-color-300);
  }
}
