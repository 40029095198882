@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.message {
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.fade {
    animation: fadeOut ease 8s;
  }
  &.success {
  }
  &.error {
  }
}
