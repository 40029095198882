.describe-incident-form {
  &__date-loss {
    width: 100%;
    max-width: 18.5rem;
    background-image: url('../components/icons/icon-calendar.png');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 11px;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }
  .react-datepicker-wrapper {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}
