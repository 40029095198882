.navigation-menu {
  font-size: 0.875rem;
  &__item {
    margin-bottom: 2.625rem;
  }
  &--item-confirm {
  }
}
.menu-item {
  position: relative;
  border-radius: 0.3125rem;
  padding: 0.5rem 1.375rem;
  color: #060920;
  border: 1px solid transparent;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:hover:not(.disabled) {
    text-decoration: none;
    color: #060920;
    background-color: #fff;
    border: 1px solid $border-color;
    @include media-breakpoint-down(lg) {
      border: 1px solid transparent;
    }
  }
  &:active,
  &:visited {
    color: #060920;
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
  }
  &__active-dot {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    display: none;
    position: absolute;
    left: 1.25rem;
    top: 45%;
    background-color: var(--menu-dot-color);
  }
  &.active {
    background-color: #fff;
    border: 1px solid $border-color;
    @include media-breakpoint-down(lg) {
      border: none;
      background-color: transparent;
    }
    .menu-item__active-dot {
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  &__arrow {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  &__check-status {
    margin-right: 0;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    @include media-breakpoint-down(lg) {
      position: relative;
      margin-right: 1rem;
      top: auto;
      left: auto;
    }
  }
}
