.collapsable {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.125rem 2.5rem;
    cursor: pointer;
    &:hover {
    }
  }
  &__header-content {
  }
  &__action {
  }
  &__body {
    padding: 1rem 1.5rem 0.5rem 0;
    // font-size: 0.75rem;
  }
  &__button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.open {
      transform: rotate(180deg);
    }
  }
}
