.payment-service-fee {
  &_summary {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &_summary-label {
    font-weight: bold;
  }
  &_summary-amount {
  }
  &_form {
    width: 100%;
    max-width: 40rem;
    margin-top: 0.6rem;
  }
  &_form-cols {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 0.6rem;
  }
  &_form-col {
    width: 100%;
  }
  &_input-error {
    color: $yellow-600;
    font-size: 0.8rem;
  }
}
.credit-card-input {
  position: relative;
  &_icon {
    position: absolute;
    top: 11px;
    right: 11px;
    width: 2rem;
  }
}
