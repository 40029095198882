.upload-proof-btn {
  width: 10.625rem;
  height: 7.625rem;
  padding: 1.8rem 0.3rem;
  border-radius: 3px;
  font-size: 0.75rem;
  text-align: center;
  cursor: pointer;
  background-color: var(--upload-proof-btn-bg);
  border: 1px dashed var(--upload-proof-btn-border);
  color: var(--upload-proof-btn-color);
  @include media-breakpoint-down(lg) {
    background-color: transparent;
    border: none;
    width: 100%;
    height: auto;
  }
  &.disabled {
    opacity: 0.3;
  }
  svg {
    margin-bottom: 0.4rem;
    path {
      stroke: var(--theme-primary-color-300);
    }
  }
  &__txt {
    display: block;
  }
  &__txt,
  &__txt-more {
    display: block;
    font-size: 0.75rem;
  }
  &__txt-more {
    display: none;
  }
  &__format {
    display: block;
    font-size: 0.625rem;
  }
  &--more {
    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center;
    }
    svg {
      margin-right: 0.375rem;
      margin-bottom: 0;
    }
    .upload-proof-btn__txt {
      display: none;
    }
    .upload-proof-btn__txt-more {
      display: block;
      margin-top: 2px;
    }
    .upload-proof-btn__format {
      display: none;
    }
  }
}
