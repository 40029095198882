.walkthrough {
  padding: 2rem;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  &-info {
    cursor: pointer;
    border: none;
    background-color: transparent;
    &:hover {
      opacity: 0.5;
    }
  }
  &_close {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: none;
  }
  &_heading {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &_title {
    font-size: 1.25rem;
    @include font-family-cera();
  }
  &_desc {
    font-size: 1rem;
    color: var(--secondary-color-300);
    margin-bottom: 2rem;
  }
  &_steps {
  }
  .step-0 {
    display: none;
    @include media-breakpoint-down(lg) {
      // display: block;
    }
  }
  &_step {
    margin-bottom: 1rem;
    @include media-breakpoint-down(lg) {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .step-1 {
  }
  .step-2 {
  }
  .step-3 {
  }
  &_footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
    }
  }
  &_akko {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    &-logo {
      height: 2rem;
      margin-right: 1rem;
    }
    &-message {
      flex-grow: 1;
      font-size: 0.875rem;
      &-title {
        @include font-family-inter-semibold();
      }
      &-desc {
        @include font-family-inter-medium();
      }
    }
  }
  &_nav {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  &_nav-mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    button {
      margin-bottom: 0.5rem;
    }
  }
}
.step-content {
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background-color: #f3f3f3;
  display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
    background-color: #fff;
    border: none;
  }
  &_banner {
    width: 18rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    img {
      width: 18rem;
      display: block;
      margin: auto;
    }
  }
  &_banner-fix {
    width: 18rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  &_body {
    flex-grow: 1;
    font-size: 0.875rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    color: var(--secondary-color-300);
  }
  &_heading {
    display: flex;
    margin-bottom: 1rem;
    font-size: 1rem;
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
  &_step-count {
    @include font-family-cera();
    color: var(--secondary-color-300);
    margin-right: 0.5rem;
  }
  &_title {
    @include font-family-cera();
    font-weight: 500;
    color: var(--dark-color);
  }
}
