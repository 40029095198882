.evidence {
  .modal-content {
    border: none;
    background-color: transparent;
  }
  .modal-body {
    padding: 0;
  }
  &__content {
    position: relative;
    border-radius: 0.375rem;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
    padding: 2.875rem;
    width: 100%;
    background-color: $white;
    // max-width: 43.125rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }
  }
  &__title {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  &__desc {
    font-size: 0.8125rem;
  }
  &__heading {
    margin-bottom: 1.625rem;
  }
  &__close {
    position: absolute;
    top: 2.875rem;
    right: 2.875rem;
    border: none;
    background-color: transparent;
    @include media-breakpoint-down(sm) {
      top: 2rem;
      right: 2rem;
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
.image {
  display: flex;
  &__sidebar {
    width: 100%;
    max-width: 6rem;
    margin-right: 0.875rem;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-right: 0;
    }
  }
  &__thumb {
    width: 6rem;
    height: 6rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border: solid #f7f7f7;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
    img {
      width: 120%;
      margin-left: -10%;
    }
    &.active {
      border: 2px solid $primary;
      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
    &:hover {
      // border: 2px solid $primary;
    }
  }
  &__view {
    overflow: hidden;
    flex-grow: 1;
    img {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
