#root,
#modals {
  // Colors
  --primary-color-300: #f3103d;
  --primary-color-200: #f5345a;
  --primary-color-100: #e78699;
  --dark-color: #060920;
  --secondary-color-400: #575757;
  --secondary-color-300: #757d8a;
  --secondary-color-200: #b9bfca;
  --secondary-color-100: #e8e8e8;
  --secondary-color-50: #f6f5f5;

  --theme-color-hsl: var(--theme-color-h), var(--theme-color-s),
    var(--theme-color-l);

  --theme-primary-color-300: hsl(var(--theme-color-hsl), 1);
  --theme-primary-color-200: hsl(var(--theme-color-hsl), 0.5);
  --theme-primary-color-100: hsl(var(--theme-color-hsl), 0.3);

  .btn-primary {
    --#{$prefix}btn-color: white;
    --#{$prefix}btn-bg: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-border-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-hover-color: white;
    --#{$prefix}btn-hover-bg: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) - 10%),
      var(--theme-color-l),
      1
    );
    --#{$prefix}btn-hover-border-color: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) - 10%),
      var(--theme-color-l),
      1
    );
    --#{$prefix}btn-active-color: white;
    --#{$prefix}btn-active-bg: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-active-border-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-disabled-color: white;
    --#{$prefix}btn-disabled-bg: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) + 30%),
      var(--theme-color-l),
      1
    );
    --#{$prefix}btn-disabled-border-color: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) + 30%),
      var(--theme-color-l),
      1
    );
  }
  .btn-outline-primary {
    --#{$prefix}btn-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-border-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-hover-color: white;
    --#{$prefix}btn-hover-bg: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-hover-border-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-active-color: white;
    --#{$prefix}btn-active-bg: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-active-border-color: hsl(var(--theme-color-hsl), 1);
    --#{$prefix}btn-disabled-color: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) + 30%),
      var(--theme-color-l),
      1
    );
    // --#{$prefix}btn-disabled-bg: transparent;
    --#{$prefix}btn-disabled-border-color: hsl(
      var(--theme-color-h),
      calc(var(--theme-color-s) + 30%),
      var(--theme-color-l),
      1
    );
    // --#{$prefix}gradient: none;
  }
  --check-status-color: hsla(var(--theme-color-hsl), 1);
  --sidebar-bg-color: hsla(var(--theme-color-hsl), 0.03);
  --menu-dot-color: hsla(var(--theme-color-hsl), 1);
  --review-box-color: hsla(var(--theme-color-hsl), 0.03);

  --tab-button-color: hsla(var(--theme-color-hsl), 1);
  --tab-button-border-color: hsla(var(--theme-color-hsl), 1);
  --tab-button-bg-color: hsla(var(--theme-color-hsl), 0.03);

  --input-border-color: hsla(var(--theme-color-hsl), 0.5);
  --input-shadow-color: hsla(var(--theme-color-hsl), 0.25);

  --check-border-color: hsla(var(--theme-color-hsl), 1);
  --check-bg-color: hsla(var(--theme-color-hsl), 1);
  --check-focus-border-color: var(--input-border-color);
  --check-focus-shadow-color: var(--input-shadow-color);

  --link-color: hsla(var(--theme-color-hsl), 1);

  --preloading-bg-color: hsla(var(--theme-color-hsl), 1);

  --upload-proof-btn-color: hsla(var(--theme-color-hsl), 1);
  --upload-proof-btn-border: hsla(var(--theme-color-hsl), 1);
  --upload-proof-btn-bg: hsla(var(--theme-color-hsl), 0.1);

  --spinner-color: hsla(var(--theme-color-hsl), 1);
  --device-picker-border-color: hsla(var(--theme-color-hsl), 1);

  --multiselect_remove_bg: hsla(var(--theme-color-hsl), 0.2);
}
.form-control {
  &:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
  }
}
.form-select {
  &:focus {
    border-color: var(--input-border-color);
    box-shadow: 0 0 0 0.25rem var(--input-shadow-color);
  }
}
.form-check-input {
  &:focus {
    border-color: var(--check-focus-border-color);
    box-shadow: 0 0 0 0.25rem var(--check-focus-shadow-color);
  }
  &:checked {
    border-color: var(--check-border-color);
    background-color: var(--check-bg-color);
  }
}
